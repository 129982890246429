var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Mededelingen")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced fill-height" },
              [
                _vm.announcements && _vm.announcements.length
                  ? _c(
                      "v-layout",
                      { staticClass: "item__list", attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { staticClass: "label", attrs: { xs12: "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs4: "" } }, [
                                  _c("span", [_vm._v("Datum")])
                                ]),
                                _c("v-flex", { attrs: { xs8: "" } }, [
                                  _c("span", [_vm._v("Omschrijving")])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._l(_vm.announcements, function(
                          announcement,
                          index
                        ) {
                          return _c(
                            "v-flex",
                            {
                              key: index,
                              staticClass: "list__item",
                              attrs: { xs12: "" }
                            },
                            [
                              _c(
                                "v-layout",
                                [
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            announcement.created_at
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _c("span", [
                                      _vm._v(_vm._s(announcement.announcement))
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  "align-center": "",
                                  "justify-space-between": "",
                                  wrap: ""
                                }
                              },
                              [
                                _c("v-flex", { staticClass: "flex-starving" }, [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.from) +
                                        " - " +
                                        _vm._s(_vm.to) +
                                        " van " +
                                        _vm._s(_vm.total) +
                                        " "
                                    )
                                  ])
                                ]),
                                _c(
                                  "v-flex",
                                  { staticClass: "flex-starving" },
                                  [
                                    _c("v-pagination", {
                                      attrs: {
                                        length: _vm.lastPage,
                                        "total-visible": 7
                                      },
                                      on: { input: _vm.paginationDebounce },
                                      model: {
                                        value: _vm.page,
                                        callback: function($$v) {
                                          _vm.page = $$v
                                        },
                                        expression: "page"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }