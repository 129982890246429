import { Component, Vue } from 'vue-property-decorator';
import { TableMeta } from '@/components/data-table/DataTable';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Announcement } from '@/models/Announcement';
import { formatDate } from '@/support/String';
import { debounce } from 'lodash';

@Component<Mededelingen>({
  filters: {
    formatDate: (date: string) => {
      if (! date) { return ''; }
      return formatDate(date);
    },
    formatDateTime: (date: string) => {
      if (! date) { return ''; }
      return formatDate(date, 'dd-LL-yyyy HH:mm');
    },
  },
})
export default class Mededelingen extends Vue {
  public $pageTitle = 'Mededelingen';

  protected isLoading = true;

  // Announcements
  protected announcements: Announcement[] = [];

  protected total = 0;

  protected lastPage = 1;

  protected from = 0;

  protected to = 0;

  protected page = 1;

  protected limit = 5;

  public paginationDebounce: Function = this.handlePagination();

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    this.emitBreadcrumb();
    this.fetchAnnouncements();
  }

  protected fetchAnnouncements() {
    new Announcement()
      .limit(this.limit)
      .page(this.page)
      .all()
      .then((announcements: Announcement[]) => {
        this.announcements = announcements;
        this.total = announcements[0] !== undefined ? (announcements[0].meta as TableMeta).total : 0;
        this.lastPage = announcements[0] !== undefined ? (announcements[0].meta as TableMeta).last_page : 1;
        this.from = announcements[0] !== undefined ? (announcements[0].meta as TableMeta).from : 0;
        this.to = announcements[0] !== undefined ? (announcements[0].meta as TableMeta).to : 0;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  public handlePagination() {
    return debounce(
      (page: number) => {
        this.page = page;
        this.fetchAnnouncements();
      },
      300,
    );
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Dashboard' },
        ],
      });
  }
}
